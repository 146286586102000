<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.humanResource.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.humanResource.list.title"></app-i18n>
      </h1>

      <app-human-resource-list-toolbar></app-human-resource-list-toolbar>
      <app-human-resource-list-filter></app-human-resource-list-filter>
      <app-human-resource-list-table></app-human-resource-list-table>
    </div>
  </div>
</template>

<script>
import HumanResourceListFilter from '@/modules/human-resource/components/human-resource-list-filter.vue';
import HumanResourceListTable from '@/modules/human-resource/components/human-resource-list-table.vue';
import HumanResourceListToolbar from '@/modules/human-resource/components/human-resource-list-toolbar.vue';

export default {
  name: 'app-human-resource-list-page',

  components: {
    [HumanResourceListFilter.name]: HumanResourceListFilter,
    [HumanResourceListTable.name]: HumanResourceListTable,
    [HumanResourceListToolbar.name]: HumanResourceListToolbar,
  },
};
</script>

<style>
</style>
